<template>
  <div class="bg-white">
    <template >
      <div>
          <HomeFilter @search="search" :prePopulateData="datesData" />
      </div>
    </template>
    <div v-if="isBusy" style="padding-top: 5.8rem; padding-bottom: 5.7rem" class="text-center">
            <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
    </div>
    <div v-else>
          <h1
          v-if="Object.keys(dataList).length === 0"
            style="padding-top: 5.8rem; padding-bottom: 5.7rem"
            class="text-center"
          >
            No Data Found Against Your Search
          </h1>
        <div v-else>
        <div
          class="rounded-lg shadow row m-4 px-2 py-2"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="col-12 col-md-4" style="height: 300px">
            <img
              :src="item.images.length > 0 ? item.images[0].path : ''"
              class="w-100 h-100"
              style="height: 100%; width: 100%"
              alt=""
            />
          </div>
          <div class="col-12 col-md-8">
            <h3 class="title mb-4 pt-1">
              {{ item.name }}
            </h3>
            <p>
              {{ item.city.name }}
              <!-- <span class="pl-2 font-weight-bold"
                ><b-link
                  @click="
                    showOnMap(item.location.longitude, item.location.latitude)
                  "
                  >Show on Map</b-link
                ></span> -->
            </p>

            <p class="card-text pt-1">
              <b>{{ item.no_of_available_rooms.total }}</b> rooms available
              <b
                v-for="(ite, i) in item.no_of_available_rooms.rooms_by_type"
                :key="i"
                >- {{ ite.count }} {{ ite.room_type_name }}
              </b>
            </p>
            <p v-if="item.no_of_available_rooms.total == 0" class="text-danger border-danger rounded p-2"><feather-icon icon="AlertTriangleIcon" size="18" class="mr-1"/>Please visit this page again on check in date to check availability of room</p>

            <h5 class="card-title">
              <p> Phone {{ item.phone_no }}</p>
            </h5>
            <p class="mt-auto">
              Starting from
              <b>{{ minPrice(item.no_of_available_rooms.rooms_by_type) }}</b>
            </p>
            <b-button
              class="mt-2 mt-lg-0"
              @click="getDetails(item.id)"
              variant="primary"
              >Get Details</b-button
            >
          </div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import HomeFilter from "@/components/booking/home/HomeFilter.vue";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {
    HomeFilter,
    FeatherIcon,
  },
  data() {
    return {
      // dataList: {},
      dataList: [],
      isMounted: false,
      isBusy: false,
      datesData:{},
    };
  },
  methods: {
    ...mapActions({
      getAvailableRooms: "appData/getAvailableRooms",
      getMessData: "appData/getMess",
    }),
    async search(localFilters) {
      this.isBusy = true
      const storedFilters = JSON.parse(localStorage.getItem("globalFilters")) || {};
      if( Object.keys(storedFilters).length !== 0 )
      {
        let tempParams = {}
        if("date_from" in storedFilters){
          tempParams['date_from']= storedFilters.date_from
        }
        if("date_to" in storedFilters){
          tempParams['date_to']= storedFilters.date_to
        }
        if("city" in storedFilters){
          tempParams['city']= storedFilters.city
        }
        if("booking_for" in storedFilters){
          tempParams['booking_for']= storedFilters.booking_for
        }
                let res = await this.getAvailableRooms(tempParams);
        
        if (res.status === 200) {
          let data = res.data.available_rooms
          if (data.length !== 0) {
            this.dataList = data;
          } else {
            this.dataList = [];
          }
        }
      }
      this.isBusy = false
    },
    minPrice(item) {
      let min = 999999;
      item.forEach(function (o) {
        Object.keys(o).forEach(function (k) {
          if (k == "room_type_price") {
            min = Math.min(min, o[k]);
          }

        });
      });
      return min;
    },
    async getDetails(id) {
      // let res = await this.getMessData(id);
      // if (res.status === 200) {
        this.$router.push({ name: "GuestHouse", query: { id: id } });
      // }
    },

    setBusyTrue() {
      this.isBusy = true;
    },

    setBusyFalse() {
     this.isBusy = false
   },
    async showOnMap(longitude, latitude) {
      const mapEmbedUrl = `https://www.google.com/maps?q=${longitude},${latitude}`;
      window.open(mapEmbedUrl, "_blank");
    },
  },
  // async created() {
  //   let data = await this.getFilterData;
  //   if (data && data.length !== 0) {
  //     this.dataList = data;
  //         } else {
  //     this.dataList = null;
  //   }
  // },
  async created(){
    this.isBusy = true
    const storedFilters = JSON.parse(localStorage.getItem("globalFilters")) || {};
        if( Object.keys(storedFilters).length !== 0 )
    {
      let tempParams = {}
      if("date_from" in storedFilters){
        tempParams['date_from']= storedFilters.date_from
      }
      if("date_to" in storedFilters){
        tempParams['date_to']= storedFilters.date_to
      }
      if("city" in storedFilters){
        tempParams['city']= storedFilters.city
      }
      if("booking_for" in storedFilters){
        tempParams['booking_for']= storedFilters.booking_for
      }
      let res = await this.getAvailableRooms(tempParams);
            
      if (res.status === 200) {
        let data = res.data.available_rooms
        if (data.length !== 0) {
          // this.dataList.push(...data)
          // Vue.set(this.dataList, 'available_rooms', data.available_rooms);
          this.dataList = data;
        } else {
          this.dataList = [];
        }
      }
    } 
    this.isBusy = false   
  },


  async mounted() {
    this.isMounted = true
    this.isBusy = true
    const storedFilters = JSON.parse(localStorage.getItem("globalFilters")) || {};
    this.datesData = storedFilters;
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      // getFilterData: "appData/getBookingFilterData",
    }),
  },
};
</script>

<style scoped>
.content-main {
  width: 60%;
  margin-left: 20% !important;
}

.card-container img {
  height: 300px !important;
  width: 40%;
}
.not-found {
  background: url("~@/assets/booking/notfound.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

</style>
